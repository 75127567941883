<template>
  <div class="main">
    <div class="header">
      <div class="headerwapper">
        <div class="headerboxone">
          <div class="wordone">SALE</div>
        </div>
        <div class="headerboxtow">
          <div class="wordtwo">全场<br>五折</div>
        </div>
        <div class="headerboxthree">
          <div class="wordthree">SALE</div>
        </div>
        <div class="headerboxfour">
          <div class="wordfour">年末<br>促销</div>
        </div>
      </div>
      <div class="headertitle">冬日礼遇</div>
      <div class="headerdes">Winter Wonderland</div>
      <div class="peoplewapper">
        <div class="peopleone"></div>
        <div class="peopletwo"></div>
        <div class="peoplethree"></div>
        <div class="peoplefour"></div>
      </div>
      <div class="descriptwapper">
        <div class="desbox">
          <div class="des">冬日总是充满遐想</div>
          <div class="des">我们需要更多的温暖</div>
          <div class="des">每个冬天，给亲爱的自己</div>
          <div class="des">亲爱的家人，亲爱的朋友</div>
          <div class="des">送上一份暖洋洋的礼物</div>
        </div>
      </div>
      <div class="video">
        <video id="video" muted="muted" loop="loop" playsinline="" preload="auto" mtt-playsinline="true" webkit-playsinline="true"
               x5-video-player-type="h5-page"
               src="../assets/22c8d709-b7f4-4b1c-a966-e85d4698f2421724446.mp4"
               poster=""
              autoplay="autoplay" class="editor-video-player__content"
               style=""></video>
      </div>
      <div class="startone"></div>
    </div>
    <div class="listone">
      <div class="listtitle">
        <div class="titlebox">
          <div class="titleone">雪宝儿暖手宝</div>
        </div>
      </div>
      <div class="xinaonoe">
        <div class="productlistone">
          <div class="productlistbox">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/20201214-113341-aa4f.png">
              </div>
            </div>
            <div class="productonetitle">雪人暖手宝二合一充电宝恒温雪宝儿便携卡通可爱移动电源(红色)</div>
            <div class="procebox">￥129</div>
            <div class="saleone"></div>
          </div>
          <div class="productlistbox active">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/20201214-113341-a1eb.png">
              </div>
            </div>
            <div class="productonetitle">雪人暖手宝二合一充电宝恒温雪宝儿便携卡通可爱移动电源(粉色)</div>
            <div class="procebox">￥129</div>
            <div class="xueone"></div>
          </div>
        </div>
      </div>
      <div class="video">
        <video id="video1" muted="muted" loop="loop" playsinline="" preload="auto" mtt-playsinline="true" webkit-playsinline="true"
               x5-video-player-type="h5-page"
               src="../assets/22c8d709-b7f4-4b1c-a966-e85d4698f2421724446.mp4"
               poster=""
               autoplay="autoplay" class="editor-video-player__content"
               style=""></video>
      </div>
    </div>
    <div class="listone">
      <div class="listtitle">
        <div class="titlebox">
          <div class="titleone">鹿兔有你暖手宝</div>
        </div>
      </div>
      <div class="xinaonoe">
        <div class="productlistone">
          <div class="productlistbox">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img002.png">
              </div>
            </div>
            <div class="productonetitle">21新款鹿兔有你暖手宝充电宝二合一暖宝宝便携式取暖充电宝(棕色)</div>
            <div class="procebox">￥129</div>
            <div class="saleone"></div>
          </div>
          <div class="productlistbox active">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img001.png">
              </div>
            </div>
            <div class="productonetitle">21新款鹿兔有你暖手宝充电宝二合一暖宝宝便携式取暖充电宝(粉色)</div>
            <div class="procebox">￥129</div>
            <div class="xueone"></div>
          </div>
        </div>
      </div>
      <div class="video">
        <video id="video2" muted="muted" loop="loop" playsinline="" preload="auto" mtt-playsinline="true" webkit-playsinline="true"
               x5-video-player-type="h5-page"
               src="../assets/22c8d709-b7f4-4b1c-a966-e85d4698f2421724446.mp4"
               poster=""
               autoplay="autoplay" class="editor-video-player__content"
               style=""></video>
      </div>
    </div>
    <div class="listone">
      <div class="listtitle">
        <div class="titlebox">
          <div class="titleone">迷你卡通暖宝宝</div>
        </div>
      </div>
      <div class="xinaonoe">
        <div class="productlistone">
          <div class="productlistbox">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img004.png">
              </div>
            </div>
            <div class="productonetitle">白熊充电宝企鹅可爱随身电暖宝宝(里小熊)</div>
            <div class="procebox">￥129</div>
            <div class="saleone"></div>
          </div>
          <div class="productlistbox active">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img003.png">
              </div>
            </div>
            <div class="productonetitle">白熊充电宝企鹅可爱随身电暖宝宝(焦头鹅)</div>
            <div class="procebox">￥129</div>
            <div class="xueone"></div>
          </div>
        </div>
      </div>
      <div class="listtitle">
        <div class="titlebox">
          <div class="titleone">国宝同款暖手宝</div>
        </div>
      </div>
      <div class="xinaonoe">
        <div class="productlistone">
          <div class="productlistbox">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img006.jpg">
              </div>
            </div>
            <div class="productonetitle">会变脸usb暖手宝可爱卡通充电式防爆电热暖宝宝迷你冬取暖神器</div>
            <div class="procebox">￥178</div>
            <div class="saleone"></div>
          </div>
          <div class="productlistbox active">
            <div class="productimg">
              <div class="productone">
                <img src="../assets/img005.png">
              </div>
            </div>
            <div class="productonetitle">羊驼暖手宝充电宝两用二合一冬天捂手暖手神器便携式学生随身女生</div>
            <div class="procebox">￥128</div>
            <div class="xueone"></div>
          </div>
        </div>
      </div>
      <div class="code">
        <div class="codebg">
          <div class="qcode"></div>
        </div>
      </div>
      <div class="video">
        <video id="video3" muted="muted" loop="loop" playsinline="" preload="auto" mtt-playsinline="true" webkit-playsinline="true"
               x5-video-player-type="h5-page"
               src="../assets/22c8d709-b7f4-4b1c-a966-e85d4698f2421724446.mp4"
               poster=""
               autoplay="autoplay" class="editor-video-player__content"
               style=""></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {

    }
  },
  created(){
    var video = document.getElementById('video');
    var video1 = document.getElementById('video1');
    var video2 = document.getElementById('video2');
    var video3 = document.getElementById('video3');
    document.addEventListener("WeixinJSBridgeReady", function () {
      setTimeout(function (){
        video.play();
        video1.play();
        video2.play();
        video3.play();
      },2000)

    }, false);
  },
}
</script>

<style scoped>
.header {
  background-image: url("../assets/20201208-204730-c6c3.jpeg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2rem;
  position: relative;
}

.headerwapper {
  position: relative;
}

.headerboxone {
  position: relative;
  width: 5rem;
  height: 14rem;
  background-image: url("../assets/20201208-204730-d4e1.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  top: 0;
}

.wordone {
  position: absolute;
  font-size: 1.1rem;
  top: 10.3rem;
  left: 1rem;
  font-style: normal;
  font-weight: 700;
  color: rgb(4, 139, 174);
}

.headerboxtow {
  position: absolute;
  width: 7rem;
  height: 9rem;
  background-image: url("../assets/20201208-204730-0bc6.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 5.3rem;
  top: 0;
}

.wordtwo {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  color: rgb(4, 139, 174);
  position: absolute;
  top: 3.6rem;
  left: 2.4rem;
}

.headerboxthree {
  position: absolute;
  width: 5rem;
  height: 5.5rem;
  background-image: url("../assets/20201208-204730-20b1.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 5.3rem;
  top: 0;
}

.wordthree {
  font-size: .8rem;
  font-style: normal;
  font-weight: 700;
  color: rgb(4, 139, 174);
  position: absolute;
  top: 2.2rem;
  left: 1.5rem;
}

.headerboxfour {
  position: absolute;
  width: 6rem;
  height: 9.5rem;
  background-image: url("../assets/20201208-204730-7412.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 0rem;
  top: 2rem;
}

.wordfour {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  color: rgb(4, 139, 174);
  position: absolute;
  top: 5rem;
  left: 1.7rem;
}

.headertitle {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  margin-top: -2rem;
}

.headerdes {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  margin-top: .5rem;
  text-align: center;
}

.peoplewapper {
  position: relative;
  padding-top: 19rem;
}

.peopleone {
  width: 3rem;
  height: 2.8rem;
  background-image: url("../assets/20201208-204730-27ec.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 15rem;
  left: 6rem;
}

.peopletwo {
  width: 3.3rem;
  height: 3.6rem;
  background-image: url("../assets/20201208-204730-44cc.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 16rem;
  left: 5rem;
}

.peoplethree {
  width: 5.6rem;
  height: 3.3rem;
  background-image: url("../assets/20201208-204730-b489.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 15rem;
  right: 6rem;
}

.peoplefour {
  width: 3.3rem;
  height: 3.5rem;
  background-image: url("../assets/20201208-204730-830c.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 16rem;
  right: 5rem;
}

.descriptwapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
}

.desbox {
  height: 9.8rem;
  width: 18rem;
  background-image: url("../assets/20201208-204730-b3e3.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: .8rem;
}

.des {
  color: rgb(255, 255, 255);
  font-size: .8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
}

.listone {
  background-image: url("../assets/20201216-105739-099f.jpeg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  padding-bottom: 5rem;
  position: relative;
}

.listtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titlebox {
  height: 3.6rem;
  width: 14rem;
  background-image: url("../assets/20201208-204730-6a8b.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleone {
  color: rgb(6, 93, 133);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}

.xinaonoe {
  width: 100%;
  background-image: url("../assets/20201208-204730-ba89.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65% 63%;
  margin-top: 4rem;
}

.productlistone {

}

.productlistbox {
  margin-bottom: 5rem;
  position: relative;
}

.productlistone .productlistbox:last-child {
  margin-bottom: 0;
}

.productimg {
  width: 17rem;
  height: 18rem;
  background-image: url("../assets/20201208-204730-ed1f.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productone {
  width: 15rem;
  height: 15rem;
}

.productone img {
  width: 100%;
  height: 100%;
}

.productonetitle {
  color: rgb(6, 93, 133);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  padding: 1rem 10rem 0 1rem;
  line-height: 1.6rem;
}

.procebox {
  color: rgb(6, 93, 133);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin-top: .5rem;
  padding: 0 10rem 0 1rem;
}

.productlistbox.active {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}

.productlistbox.active .productimg {
  background-image: url("../assets/20201208-204730-8254.png");
}

.productlistbox.active .productonetitle {
  padding: 1rem 1rem 0 10rem;
}

.productlistbox.active .procebox {
  padding: 1rem 1rem 0 10rem;
}

.code {
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
}

.codebg {
  width: 12rem;
  height: 12rem;
  background-image: url("../assets/20201214-112823-e6c4.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qcode {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-image: url("../assets/20201214-112823-ef7e.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.video{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}
video {
  mix-blend-mode: screen;
  width: 100%;
  height: 100%;
}
.startone{
  width: 4.5rem;
  height: 4.8rem;
  position: absolute;
  right: -.95rem;
  bottom: 0;
  background-image: url("../assets/20201208-204730-a457.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.saleone{
  width: 7.5rem;
  height:7rem;
  position: absolute;
  right: 1rem;
  top: 6rem;
  background-image: url("../assets/20201208-204730-4cf5.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.xueone{
  width: 5.5rem;
  height:5.9rem;
  position: absolute;
  left: 3rem;
  bottom: -3rem;
  background-image: url("../assets/20201208-204730-55b4.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>